import { useGetOne } from 'react-admin';

export function useAutomationSuggestions(claimId) {
  return useGetOne(
    'claims',
    { id: `${claimId}/automation-suggestions` },
    {
      select: response => {
        if (Array.isArray(response)) {
          return response.filter(
            item => typeof item === 'object' && !Array.isArray(item)
          );
        }
        return [];
      },
    }
  );
}
