import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { formatCurrency } from '../../shared/utils/currencyUtils';
import { getTransactionFee } from '../../shared/utils/petUtils';
import useStyles from './CancellationStyles';

function getRefundInfo(classes, cancellation, type, pet_plan) {
  return (
    <div>
      {type === 'Policy' ? (
        <div>
          <Typography className={classes.titleText}>INSURANCE REFUND:</Typography>
          <Typography className={classes.infoText}>
            {formatCurrency(cancellation.insurance_refund)}
          </Typography>
        </div>
      ) : null}
      {pet_plan ? (
        <div>
          <Typography className={classes.titleText}>PREVENT REFUND:</Typography>
          <Typography className={classes.infoText}>
            {formatCurrency(cancellation.outstanding_balance)}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

function getPolicyInfo(classes, policy, chargeType) {
  return (
    <div>
      <Typography className={classes.titleText}>PUMPKIN PET INSURANCE:</Typography>
      <Typography className={classes.infoText}>
        {formatCurrency(policy.premium)} / {chargeType} (
        {policy.multiple_pet_discount_percentage
          ? policy.multiple_pet_discount_percentage
          : 0.0}
        % DISCOUNT)
      </Typography>
      <Typography className={classes.infoText}>
        EFFECTIVE DATE:
        {moment.parseZone(policy.policy_effective_date).format('MM/DD/YYYY')}
      </Typography>
      <Typography className={classes.infoText}>
        END DATE: {moment.parseZone(policy.policy_end_date).format('MM/DD/YYYY')}
      </Typography>

      <Typography className={classes.infoText}>
        PROGRAM VERSION: {policy.program_id}
      </Typography>

      <Typography className={classes.infoText}>
        POLICY YEAR: {policy.year}
      </Typography>
    </div>
  );
}

function getPetPlanInfo(classes, pet_plan, chargeType) {
  return (
    <div>
      <Typography className={classes.titleText}>PREVENTIVE ESSENTIALS:</Typography>
      <Typography className={classes.infoText}>
        {formatCurrency(pet_plan.cost)} / {chargeType}
      </Typography>
      <Typography className={classes.infoText}>
        EFFECTIVE DATE:{' '}
        {moment.parseZone(pet_plan.plan_effective_date).format('MM/DD/YYYY')}
      </Typography>
      <Typography className={classes.infoText}>
        END DATE: {moment.parseZone(pet_plan.plan_end_date).format('MM/DD/YYYY')}
      </Typography>
    </div>
  );
}

function createCancellationItem(cancellation) {
  const classes = useStyles();

  const type = cancellation.cancellation_type;
  const chargeType = cancellation.user.is_charged_annually ? 'YR' : 'MO';

  let policy;
  let pet_plan;

  policy = cancellation.policy;
  pet_plan = cancellation.pet_plan;

  return (
    <Accordion style={{ marginBottom: '12px' }} key={cancellation.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div>
          <Typography component={'div'} className={classes.petName}>
            {cancellation.pet.name}
          </Typography>
          <Typography component={'div'} className={classes.infoText}>
            PET ID: {cancellation.pet.species}({cancellation.pet?.gender}) AGE:{' '}
            {cancellation.pet.age}
          </Typography>
          <Typography component={'div'} className={classes.titleText}>
            CANCELLATION TYPE:&nbsp;
            <div
              style={type === 'Policy' ? { color: '#3397FF' } : { color: '#3EC28F' }}
            >
              {cancellation.cancellation_type}
            </div>
          </Typography>
          <Typography component={'div'} className={classes.infoText}>
            REQUESTED DATE:{' '}
            {moment.parseZone(cancellation.created_at).format('MM/DD/YYYY')}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {getRefundInfo(classes, cancellation, type, pet_plan)}
        <Divider style={{ marginBottom: '16px' }} />
        {type === 'Policy' ? getPolicyInfo(classes, policy, chargeType) : null}
        {pet_plan ? getPetPlanInfo(classes, pet_plan, chargeType) : null}
        <Typography className={classes.titleText}>TRANSACTION FEE:</Typography>
        <Typography className={classes.infoText}>
          {formatCurrency(getTransactionFee(cancellation.user))} / {chargeType}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function CancellationsAccordion({ record, classes }) {
  return (
    <div style={{ paddingRight: '32px' }}>
      <Typography className={classes.titleStyle}>Cancellations</Typography>
      {record.cancellation_assignments.map(createCancellationItem)}
    </div>
  );
}

CancellationsAccordion.defaultProps = {
  addLabel: true,
};

export default CancellationsAccordion;
