import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  REIMBURSEMENT_RATE_80,
  REIMBURSEMENT_RATE_90,
} from '../../../../constants/quoteFlow';
import { formatCurrency } from '../../../../shared/utils';
import { formatAgeRange } from '../../../../shared/utils/planUtils';
import {
  getDiscountDetail,
  getTransactionFee,
  putPetQuoteDetail,
} from '../service/quoteFlowService';
import { formatDiscountName } from '../utils/quoteUtils';
import { petQuoteCardStyle } from './PetQuoteCardStyle';
import PlanMenuItem from './PlanMenuItem';
import PlanModal from './PlanModal';

function PetQuoteCard(props) {
  const {
    quotePet,
    quote,
    setQuote,
    products,
    isLoading,
    setIsLoading,
    billingOption,
    setBillingOption,
    setRadioButtonBillingOptionController,
  } = props;
  const { fer4782CoinsuranceRatePicker } = useFlags();
  const classes = petQuoteCardStyle();
  const notify = useNotify();
  const navigate = useNavigate();

  const [policyOptions, setPolicyOptions] = useState({
    deductibles: [],
    limits: [],
    prices: null,
  });

  const [deductible, setDeductible] = useState('');
  const [annualLimit, setAnnualLimit] = useState('');
  const [includePreventative, setIncludePreventative] = useState(false);
  const isBilledAnnually =
    billingOption === 'annually' || quote.existingUserDetails?.is_charged_annually;
  const [totalPetPrice, setTotalPetPrice] = useState(0);

  const [showDialog, setShowDialog] = useState(false);

  const [reimbursementRate, setReimbursementRate] = useState(REIMBURSEMENT_RATE_90);

  const deductibleOptions = policyOptions.deductibles
    .sort((a, b) => a.id - b.id)
    .map(option => (
      <MenuItem key={option.id} value={option.id}>
        {option.title}
      </MenuItem>
    ));

  const limitOptions = policyOptions.limits
    .sort((a, b) => a.id - b.id)
    .map(option => (
      <MenuItem key={option.id} value={option.id}>
        {option.title}
      </MenuItem>
    ));

  const isPreventAvailable = products ? products.prevent : false;

  const quotePetPrice = quotePet.totalPrice;
  useEffect(() => {
    if (!!quotePetPrice && totalPetPrice !== quotePetPrice) {
      setTotalPetPrice(quotePet.totalPrice);
    }
  }, [quotePetPrice, totalPetPrice]);

  useEffect(() => {
    if (!quotePet) {
      return;
    }

    let _deductible;
    let _annualLimit;

    if (quotePet.annual_limit) {
      _annualLimit = quotePet.annual_limit;
      setAnnualLimit(_annualLimit);
    }

    if (quotePet.deductible) {
      _deductible = quotePet.deductible;
      setDeductible(_deductible);
    }

    setIncludePreventative(quotePet.has_prevent);

    if (quotePet.totalPrice) {
      setTotalPetPrice(
        quote.existingUserDetails?.is_charged_annually
          ? quotePet.totalPrice * 12
          : quotePet.totalPrice
      );
    }

    if (quotePet._options) {
      setPolicyOptions(quotePet._options);

      if (!quotePet.deductible && quotePet._options.deductibles.length > 0) {
        _deductible = quotePet._options.deductibles[0].id;
        setDeductible(_deductible);
      }

      if (!quotePet.annual_limit && quotePet._options.limits.length > 0) {
        _annualLimit = quotePet._options.limits[0].id;
        setAnnualLimit(_annualLimit);
      }

      if (
        !quotePet.totalPrice &&
        quotePet._options.prices &&
        _annualLimit &&
        _deductible
      ) {
        const coinsurance = quotePet.coinsurance || '10';
        const _price =
          quotePet._options.prices[coinsurance][_annualLimit][_deductible];
        setTotalPetPrice(_price);
      }
    }
  }, [quotePet, quote.existingUserDetails]);

  useEffect(() => {
    if (billingOption === 'annually') {
      setTotalPetPrice(totalPetPrice * 12);
    } else if (billingOption === 'monthly') {
      setTotalPetPrice(totalPetPrice / 12);
    }
  }, [billingOption]);

  function handleReimbursementRateChange(event) {
    updateQuote(deductible, annualLimit, event.target.value, includePreventative);
  }

  function handleDeductibleChange(event) {
    updateQuote(
      event.target.value,
      annualLimit,
      reimbursementRate,
      includePreventative
    );
  }

  function handleAnnualLimitChange(event) {
    updateQuote(
      deductible,
      event.target.value,
      reimbursementRate,
      includePreventative
    );
  }

  function handleEditPetClick() {
    setBillingOption('monthly');
    setRadioButtonBillingOptionController('monthly');

    navigate(props.edit ? `/quotes/${quote.id}/1` : '/quotes/create/1', {
      state: {
        from: 'checkout',
      },
    });
  }

  function handleIncludePreventativeChange(event) {
    setIncludePreventative(event.target.value);

    const quotePets = quote.quote_pets.map(pet => {
      if (pet.id === quotePet.id) {
        if (event.target.value === true) {
          pet.totalPrice = pet.totalPrice + pet.plan.cost;
          pet.has_prevent = true;
        } else {
          pet.has_prevent = false;
          pet.totalPrice = pet.totalPrice - pet.plan.cost;
        }
      }
      return pet;
    });
    setTotalPetPrice(quotePet.totalPrice);
    updateQuote(deductible, annualLimit, reimbursementRate, event.target.value, {
      ...quote,
      quote_pets: quotePets,
    });
  }

  function updateQuote(
    changedDeductible,
    changedLimit,
    changedReimbursementRate,
    hasPrevent,
    quoteWithPrevent = { ...quote }
  ) {
    setIsLoading(true);

    if (deductible !== changedDeductible) {
      setDeductible(changedDeductible);
    }

    if (annualLimit !== changedLimit) {
      setAnnualLimit(changedLimit);
    }

    if (reimbursementRate !== changedReimbursementRate) {
      setReimbursementRate(changedReimbursementRate);
    }

    const quoteAux = quoteWithPrevent;

    quoteAux.quote_pets = quoteAux.quote_pets.map(pet => {
      if (pet.id === quotePet.id) {
        let price;
        if (changedLimit === 'Unlimited') {
          try {
            price =
              policyOptions.prices[changedReimbursementRate][changedLimit][
                changedDeductible
              ];
          } catch (e) {
            // try/catch here due to differing C&F endpoints whether this is a new customer or AAP
            price =
              policyOptions.prices[changedReimbursementRate]['1000000'][
                changedDeductible
              ];
          }
        } else {
          price =
            policyOptions.prices[changedReimbursementRate][changedLimit][
              changedDeductible
            ];
        }

        if (hasPrevent) {
          pet.totalPrice = price + pet.plan.cost;
        } else {
          pet.totalPrice = price;
        }
        setTotalPetPrice(pet.totalPrice);
      }
      return pet;
    });

    const quoteDetail = quoteAux.quote_pets.map(pet => {
      return {
        quote_pet_id: pet.id,
        deductible: pet.id === quotePet.id ? changedDeductible : undefined,
        annual_limit: pet.id === quotePet.id ? changedLimit : undefined,
        plan_id:
          pet.id === quotePet.id ? (hasPrevent ? pet.plan.id : null) : undefined,
        coinsurance: pet.id === quotePet.id ? changedReimbursementRate : undefined,
      };
    });

    putPetQuoteDetail(quoteDetail, quote.id).then(() => {
      getTransactionFee(quote.id)
        .then(transactionFeeResponse => {
          quoteAux.transaction_fee = transactionFeeResponse.data.transaction_fee;

          getDiscountDetail(quote.id).then(discountResponse => {
            discountResponse.forEach(discount => {
              quoteAux.quote_pets.forEach(pet => {
                if (discount.id === pet.id) {
                  if (pet.has_prevent) {
                    pet.totalPrice =
                      discount.price_after_discount + quotePet.plan.cost;
                  } else {
                    pet.totalPrice = discount.price_after_discount;
                  }

                  if (pet.id === quotePet.id) {
                    setTotalPetPrice(pet.totalPrice);
                  }

                  if (discount.discounts.length > 0) {
                    pet.discount = discount.discounts;
                  } else if (pet.discount?.length > 0) {
                    pet.discount = null;
                  }
                }
              });
            });

            quoteAux.quote_pets.forEach(pet => {
              if (pet.id === quotePet.id) {
                if (isBilledAnnually) {
                  setTotalPetPrice(pet.totalPrice * 12);
                }
                pet.deductible = changedDeductible;
                pet.annual_limit = changedLimit;
                pet.coinsurance = changedReimbursementRate;
              }
            });

            setQuote(quoteAux);
            setIsLoading(false);
          });
        })
        .catch(e => {
          notify(e.message, 'warning');
          setIsLoading(false);
        });
    });
  }

  return isLoading ? (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '32px',
        marginBottom: '18px',
        padding: '20px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxSizing: 'border-box',
        display: 'flex',
        width: '720px',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <div
        style={{
          marginTop: '20px',
          marginLeft: '32px',
          padding: '24px 20px 16px',
          width: '850px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '160px' }}>
            <Typography className={classes.label}>
              {quotePet.species} ({quotePet?.gender})
            </Typography>
            <Typography className={classes.petName}>{quotePet.name}</Typography>
            <Typography className={classes.petDetails}>
              {quotePet?.breed_name}
            </Typography>
            <Typography className={classes.petDetails}>
              {isNaN(quotePet?.age)
                ? quotePet?.age
                : quotePet?.age > 1
                  ? `${quotePet?.age} years old`
                  : `${quotePet?.age} year old`}
            </Typography>
          </div>

          <div style={{ flexGrow: '1' }}>
            <div style={{ display: 'flex' }}>
              {fer4782CoinsuranceRatePicker ? (
                <div className={classes.reimbursementRateContainer}>
                  <InputLabel
                    id='reimbursement-rate-label'
                    className={classes.label}
                  >
                    REIMBURSEMENT RATE
                  </InputLabel>
                  <ToggleButtonGroup
                    value={reimbursementRate}
                    exclusive
                    onChange={handleReimbursementRateChange}
                    labelId='reimbursement-rate'
                    size='large'
                    className={classes.buttonGroup}
                  >
                    <ToggleButton
                      className={classes.toggleButton}
                      value={REIMBURSEMENT_RATE_80}
                    >
                      80 %
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggleButton}
                      value={REIMBURSEMENT_RATE_90}
                    >
                      90 %
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              ) : null}

              <div
                className={
                  fer4782CoinsuranceRatePicker
                    ? classes.deductibleContainer33
                    : classes.deductibleContainer50
                }
              >
                <InputLabel id='deductible-label' className={classes.label}>
                  DEDUCTIBLE
                </InputLabel>
                <Select
                  labelId='deductible-label'
                  value={deductible}
                  onChange={handleDeductibleChange}
                  fullWidth={true}
                  className={classes.select}
                >
                  {deductibleOptions}
                </Select>
              </div>
              <div
                className={
                  fer4782CoinsuranceRatePicker
                    ? classes.annualLimitContainer33
                    : classes.annualLimitContainer50
                }
              >
                <InputLabel id='annualLimit-label' className={classes.label}>
                  ANNUAL LIMIT
                </InputLabel>
                <Select
                  labelId='annualLimit-label'
                  value={annualLimit}
                  onChange={handleAnnualLimitChange}
                  fullWidth={true}
                  className={classes.select}
                >
                  {limitOptions}
                </Select>
              </div>
            </div>

            {isPreventAvailable && quotePet.plan ? (
              <div className={classes.plansContainer}>
                <InputLabel id='prevent-label' className={classes.label}>
                  PREVENTIVE ESSENTIALS PLAN
                  {includePreventative
                    ? `: ${formatCurrency(quotePet.plan.cost)}`
                    : null}
                </InputLabel>
                <Select
                  labelId='prevent-label'
                  value={includePreventative}
                  onChange={handleIncludePreventativeChange}
                  fullWidth={true}
                  className={classes.select}
                  renderValue={value =>
                    value === false
                      ? 'None'
                      : `${quotePet.plan.name} ${formatAgeRange(
                          quotePet.plan.min_age_months,
                          quotePet.plan.max_age_months
                        )}`
                  }
                >
                  <MenuItem
                    key='none'
                    value={false}
                    style={{ paddingTop: '16px', paddingBottom: '16px' }}
                  >
                    None
                  </MenuItem>
                  <PlanMenuItem
                    key={quotePet.plan.id}
                    value={true}
                    plan={quotePet.plan}
                  />
                </Select>
              </div>
            ) : null}
          </div>

          <div style={{ width: '140px', textAlign: 'right' }}>
            <IconButton
              style={{ fontSize: '15px', padding: '0px', color: '#3F51B5' }}
              onClick={() => setShowDialog(!showDialog)}
              size='large'
            >
              <VisibilityIcon style={{ paddingRight: '3px' }} /> PLAN
            </IconButton>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginTop: '8px',
          }}
        >
          <div style={{ width: '160px' }}>
            <IconButton
              onClick={handleEditPetClick}
              style={{ fontSize: '15px', padding: '0' }}
              size='large'
            >
              <EditIcon /> EDIT
            </IconButton>
          </div>
          <div style={{ textAlign: 'right' }}>
            {quotePet.discount
              ? quotePet.discount.map(discount => {
                  return (
                    <Typography
                      key={discount.name}
                      style={{
                        color: '#D4424E',
                        fontSize: '12px',
                        lineHeight: '10px',
                        paddingBottom: '8px',
                      }}
                    >
                      {formatDiscountName(discount, {
                        partner: quotePet.partner,
                      })}{' '}
                      {formatCurrency(
                        -(isBilledAnnually ? discount.amount * 12 : discount.amount)
                      )}
                    </Typography>
                  );
                })
              : null}

            <Typography
              style={{ fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
            >
              {isBilledAnnually
                ? `Annual Total: ${totalPetPrice.toFixed(2)}`
                : `Monthly Total: ${totalPetPrice.toFixed(2)}`}
            </Typography>
          </div>
        </div>
      </div>

      <PlanModal
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        quotePet={quotePet}
        annualCoverage={annualLimit}
        annualDeductible={deductible}
        billingOption={billingOption}
        quote={quote}
      />
    </>
  );
}

PetQuoteCard.propTypes = {
  quotePet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    species: PropTypes.string.isRequired,
    gender: PropTypes.string,
    name: PropTypes.string.isRequired,
    breed_name: PropTypes.string,
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPrice: PropTypes.number.isRequired,
    annual_limit: PropTypes.string,
    coinsurance: PropTypes.string,
    deductible: PropTypes.string,
    discount: PropTypes.array,
    has_prevent: PropTypes.bool,
    partner: PropTypes.string,
    preventDescription: PropTypes.string,
    plan: PropTypes.shape({
      web_description: PropTypes.string,
      cost: PropTypes.number,
    }),
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        addon: PropTypes.shape({
          sku: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    _options: PropTypes.shape({
      deductibles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
      limits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
      prices: PropTypes.object,
    }),
  }).isRequired,
  quote: PropTypes.object.isRequired,
  setQuote: PropTypes.func.isRequired,
  products: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  billingOption: PropTypes.string.isRequired,
  setBillingOption: PropTypes.func.isRequired,
  setRadioButtonBillingOptionController: PropTypes.func.isRequired,
};

export default PetQuoteCard;
