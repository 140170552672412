import { dateIsAfter, parseAndFormatDate } from '../../../shared/utils';
import { petHasValidPlan } from '../../../shared/utils/petUtils';

export const CANCELLATION_TYPES = {
  preventOnly: { id: 0b01, name: 'Prevent Only' },
  insuranceOnly: { id: 0b10, name: 'Insurance Only' },
  insuranceAndPrevent: { id: 0b11, name: 'Insurance + Prevent' },
  wellnessOnly: { id: 0b100, name: 'Wellness Only' },
  insuranceAndWellness: { id: 0b101, name: 'Insurance + Wellness' },
};

export function cancellationTypesFor(pets, petId) {
  return pets.find(pet => pet.id === petId)?.cancellationTypes ?? [];
}

/*
 * @param {array} pets
 * @param {string} petId
 * @param {string} product
 * product is one of 'policy', 'plan', 'wellness'
 */
export function productsFor(pets, petId, product) {
  return pets.find(pet => pet.id === petId)?.[product] ?? [];
}

export function isCancelPolicy(type) {
  return [
    CANCELLATION_TYPES.insuranceOnly.id,
    CANCELLATION_TYPES.insuranceAndPrevent.id,
    CANCELLATION_TYPES.insuranceAndWellness.id,
  ].includes(type);
}

export function isCancelPlan(type) {
  return [
    CANCELLATION_TYPES.preventOnly.id,
    CANCELLATION_TYPES.insuranceAndPrevent.id,
  ].includes(type);
}

export function isCancelWellness(type) {
  return [
    CANCELLATION_TYPES.wellnessOnly.id,
    CANCELLATION_TYPES.insuranceAndWellness.id,
  ].includes(type);
}

export function validateCancellationForm(values) {
  const errors = {};

  if (
    values.cancellation_requested_date &&
    dateIsAfter(values.cancellation_requested_date, new Date())
  ) {
    errors.cancellation_requested_date = ['Requested date cannot be in the future'];
  }

  if (values.end_date && dateIsAfter(values.end_date, new Date())) {
    errors.end_date = ['End date cannot be in the future'];
  }

  return errors;
}

const PRODUCT_MAP = {
  policy: {
    start: 'policy_effective_date',
    end: 'policy_end_date',
    productName: 'Policy',
  },
  plan: {
    start: 'plan_effective_date',
    end: 'plan_end_date',
    productName: 'Plan',
  },
  wellness: {
    start: 'start_date',
    end: 'end_date',
    productName: 'Wellness',
  },
};
function transformProducts(products, type) {
  const format = 'MM/DD/YYYY';

  return (products || [])
    .filter(product => ['active', 'lapsed'].includes(product?.status.toLowerCase()))
    .map(product => {
      const { start, end, productName } = PRODUCT_MAP[type];
      const startDate = parseAndFormatDate(product[start], format);
      const endDate = parseAndFormatDate(product[end], format);

      return {
        id: product.id,
        name: `${productName} (${startDate} - ${endDate})`,
        policy_status: product.status.toUpperCase(),
      };
    });
}

function getCancellationTypesWithSaw(pet) {
  const options = [];

  if (pet.policies?.length && !petHasValidPlan(pet)) {
    options.push(CANCELLATION_TYPES.insuranceOnly);
  }

  if (pet.wellness) {
    options.push(CANCELLATION_TYPES.wellnessOnly);
  }

  if (pet.wellness && pet.policies?.length) {
    options.push(CANCELLATION_TYPES.insuranceAndWellness);
  }

  if (pet.latest_active_plan && pet.policies?.length) {
    options.push(CANCELLATION_TYPES.insuranceAndPrevent);
  }

  if (pet.latest_active_plan) {
    options.push(CANCELLATION_TYPES.preventOnly);
  }

  return options;
}

function getCancellationTypes(pet) {
  const options = [];

  if (pet.latest_active_plan) {
    options.push({ id: 0b11, name: 'Policy and Plan' });
  } else {
    options.push({ id: 0b10, name: 'Policy Only' });
  }

  if (pet.latest_active_plan) {
    options.push({ id: 0b01, name: 'Plan Only' });
  }

  return options;
}

// remove isWellnessEnabled with punks1012SawWork
export function transformPetsForCancellation(pets, isWellnessEnabled = false) {
  return pets
    .filter(
      pet =>
        pet.latest_active_policy ||
        (isWellnessEnabled &&
          (pet.wellness?.status === 'active' || pet.wellness?.status === 'lapsed'))
    )
    .map(pet => {
      return {
        id: pet.id,
        name: pet.name,
        cancellationTypes: isWellnessEnabled
          ? getCancellationTypesWithSaw(pet)
          : getCancellationTypes(pet),
        plans: transformProducts(pet.plans, 'plan'),
        policies: transformProducts(pet.policies, 'policy'),
        wellness: transformProducts([pet.wellness], 'wellness'),
      };
    });
}

export function mountRequestPayload(data) {
  const keep = [
    'pet_id',
    'cancel_policy',
    'cancel_plan',
    'cancel_wellness',
    'end_date',
    'cancellation_requested_date',
    'reason',
  ];

  return data.map(cancellation =>
    keep.reduce((acc, curr) => {
      acc[curr] = cancellation[curr];
      return acc;
    }, {})
  );
}

export function parseErrors(cancellations, err) {
  return Object.keys(err.body.errors).map(key => {
    return {
      pet_name: cancellations[key].pet_name,
      error: true,
      message: JSON.stringify(err.body.errors[key]),
    };
  });
}

export function parseResult(cancellations, data) {
  let cancellationsWithErrors = [...cancellations];

  const results = [];

  cancellations.forEach(c => {
    const result = {
      pet_name: c.pet_name,
      error: false,
    };

    const { error, message } = data.find(item => item.pet_id === c.pet_id);
    if (error) {
      result['error'] = true;
      result['message'] = message;
    } else {
      result['message'] = 'Success';
      cancellationsWithErrors = cancellationsWithErrors.filter(
        item => item.pet_id !== c.pet_id
      );
    }

    results.push(result);
  });

  return {
    results,
    cancellationsWithErrors,
  };
}
