import { useState } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { PERMISSION_CREATE_CUSTOMER_INVOICE } from '../../auth/permissions';
import ArrayTextField from '../../components/ArrayTextField';
import { MoneyField } from '../../components/MoneyField';
import RestrictedAction from '../../components/security/RestrictedAction';
import StatusChip from '../../components/StatusChip';
import { PRODUCT_STATUS_COLORS } from '../../constants/product-status-colors';
import { useRefundFilterByUser } from '../../lib/users';
import RoutePaths from '../../routes';
import { parseAndFormatDate } from '../../shared/utils';
import { CustomerInvoiceShow } from '../customers';
import useStyles from './CancellationStyles';

function RefundsFilter({ filterData, setReferenceFilter, record }) {
  const { refund_terms, pet_names, product_names } = filterData;

  const [termFilter, setTermFilter] = useState('');
  const [petNamesFilter, setPetNamesFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');

  function buildProductOptions(products) {
    return products?.map(product => (
      <MenuItem key={product.key} value={product.value}>
        {product.value}
      </MenuItem>
    ));
  }

  function buildPetOptions(pets) {
    return pets?.map(pet => (
      <MenuItem key={pet.id} value={pet.name}>
        {pet.name}
      </MenuItem>
    ));
  }

  function buildTermOptions(terms) {
    return terms?.map(term => (
      <MenuItem key={term.id} value={term.id}>
        <Box>
          <Typography sx={{ fontWeight: 500 }}>
            {term.pet_name} - {term.product_name}
          </Typography>
          <Typography>
            {parseAndFormatDate(term.start_date)} -{' '}
            {parseAndFormatDate(term.end_date)} -{' '}
            {PRODUCT_STATUS_COLORS[term.status.toUpperCase()].name}
          </Typography>
        </Box>
      </MenuItem>
    ));
  }

  function clearFilters(filter_type) {
    if (filter_type === 'term') {
      setTermFilter('');
      setReferenceFilter(previousFilter => ({ ...previousFilter, id: undefined }));
    }
    if (filter_type === 'name') {
      setPetNamesFilter('');
      setReferenceFilter(previousFilter => ({
        ...previousFilter,
        pet_names: undefined,
      }));
    }
    if (filter_type === 'product') {
      setProductFilter('');
      setReferenceFilter(previousFilter => ({
        ...previousFilter,
        pet_products: undefined,
      }));
    }
  }

  function setReferenceFilterValue(event) {
    const { value, name } = event.target;
    const user_id = record.user_id;
    if (name === 'pets-select') {
      setReferenceFilter(previousFilter => ({
        ...previousFilter,
        user_id,
        pet_names: value,
      }));
      setPetNamesFilter(value);
    }

    if (name === 'terms-select') {
      setReferenceFilter(previousFilter => ({
        ...previousFilter,
        user_id,
        id: value,
      }));
      setTermFilter(value);
    }

    if (name === 'product-select') {
      setReferenceFilter(previousFilter => ({
        ...previousFilter,
        user_id,
        pet_products: value,
      }));
      setProductFilter(value);
    }
  }

  const SELECT_PARAMS = {
    refund_terms: {
      value: termFilter,
      order: 1,
      label: 'Term',
      labelId: 'terms-select-label',
      id: 'terms-select',
      onChange: e => setReferenceFilterValue(e),
      onClearFilter: () => clearFilters('term'),
      options: buildTermOptions(refund_terms),
    },
    pet_names: {
      value: petNamesFilter,
      order: 2,
      label: 'Pet Names',
      labelId: 'pets-select-label',
      id: 'pets-select',
      onChange: e => setReferenceFilterValue(e),
      onClearFilter: () => clearFilters('name'),
      options: buildPetOptions(pet_names),
    },
    product_names: {
      value: productFilter,
      order: 3,
      label: 'Product',
      labelId: 'product-select-label',
      id: 'product-select',
      onChange: e => setReferenceFilterValue(e),
      onClearFilter: () => clearFilters('product'),
      options: buildProductOptions(product_names),
    },
  };

  return Object.keys(filterData).map(key => {
    if (SELECT_PARAMS[key]) {
      const filterItem = SELECT_PARAMS[key];
      const { label, labelId, id, onChange, onClearFilter, value, options } =
        filterItem;
      return (
        <FormControl key={key} sx={{ marginRight: '4px', width: 300 }}>
          <InputLabel variant='outlined' id={'term-select-label'}>
            {label}
          </InputLabel>
          <Select
            labelId={labelId}
            label={label}
            variant='outlined'
            id={id}
            name={id}
            value={value}
            sx={{ padding: '8px 0 !important', height: '60px' }}
            onChange={onChange}
            endAdornment={
              value && (
                <InputAdornment sx={{ marginRight: '10px' }} position='end'>
                  <IconButton
                    style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                    onClick={onClearFilter}
                    size='small'
                  >
                    <CloseIcon sx={{ width: '20px', marginRight: '12px' }} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {options}
          </Select>
        </FormControl>
      );
    }
  });
}

export default function RefundsCard({ record }) {
  const classes = useStyles();
  const { punks1012SawWork } = useFlags();
  record.user_id = record.id;

  const [referenceFilter, setReferenceFilter] = useState({});
  const refundsRedirect = `/${RoutePaths.cancellationDetails}/${record.user_id}/show`;
  const { data: filterData = {} } = useRefundFilterByUser(record.user_id);

  return (
    <div style={{ marginBottom: '32px' }}>
      {punks1012SawWork ? (
        <div style={{ marginBottom: '32px' }}>
          <Typography variant='h5' sx={{ marginBottom: '12px' }}>
            1. Submit Refunds
          </Typography>

          <RefundsFilter
            filterData={filterData}
            setReferenceFilter={setReferenceFilter}
            record={record}
          />
          <ReferenceManyField
            label=''
            record={record}
            reference={RoutePaths.invoices}
            target={'user_id'}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={referenceFilter}
          >
            <Card>
              <CardContent>
                <Datagrid
                  rowClick='expand'
                  bulkActionButtons={false}
                  expand={
                    <CustomerInvoiceShow
                      refundsRedirect={refundsRedirect}
                      showWarning={false}
                      showCancellationLink={true}
                    />
                  }
                >
                  <ArrayTextField />
                  <TextField label='Invoice Id' source='invoice_number' />
                  <ArrayTextField label='Product' source={'product_names'} />
                  <MoneyField label='Total' source='total' />
                  <DateField label='Date' source='created_at' />
                  <StatusChip />
                  <MoneyField
                    style={{ color: 'red' }}
                    source='total_refund'
                    label='Total Refunded'
                  />
                </Datagrid>
              </CardContent>
            </Card>
          </ReferenceManyField>
        </div>
      ) : (
        <div style={{ marginBottom: '32px' }}>
          <Typography className={classes.titleStyle}>Refunds</Typography>

          <Card>
            <CardContent>
              <ReferenceManyField
                label=''
                record={record}
                reference={RoutePaths.invoices}
                target={'user_id'}
                sort={{ field: 'created_at', order: 'DESC' }}
              >
                <Datagrid
                  rowClick='expand'
                  bulkActionButtons={false}
                  expand={
                    <CustomerInvoiceShow
                      refundsRedirect={refundsRedirect}
                      showWarning={false}
                      showCancellationLink={true}
                    />
                  }
                >
                  <ArrayTextField />
                  <TextField label='Invoice Id' source='invoice_number' />
                  <MoneyField label='Total' source='total' />
                  <DateField label='Date' source='created_at' />
                  <StatusChip />
                  <MoneyField
                    style={{ color: 'red' }}
                    source='total_refund'
                    label='Total Refunded'
                  />
                </Datagrid>
              </ReferenceManyField>
            </CardContent>
          </Card>
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography
          className={classes.titleStyle}
          style={{ paddingRight: '16px', marginLeft: 'auto' }}
        >
          Need to charge outstanding balance?
        </Typography>
        <RestrictedAction requiredPermissions={PERMISSION_CREATE_CUSTOMER_INVOICE}>
          <CreateButton
            label='Create New Invoice'
            resource={RoutePaths.invoices}
            state={{ record: { customer: record.user }, showCancellationLink: true }}
          />
        </RestrictedAction>
      </div>
    </div>
  );
}
RefundsCard.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.string,
  }),
};

RefundsCard.defaultProps = {
  record: {},
};
