export const ALL = 'All';
export const SUBMITTED = 'Submitted';
export const IN_REVIEW = 'In Review';
export const COMPLETED = 'Completed';
export const DUPLICATE = 'Duplicate';
export const PENDING_ASSIGNMENT = 'Pending Assignment';

export const APPROVED = 'Approved';
export const DECLINED = 'Declined';
export const INSURANCE_PENDING = 'Insurance Pending';
export const DECIDE_LATER = 'Pending';
export const REIMBURSED = 'Reimbursed';
export const PARTIALLY_REIMBURSED = 'Partially Reimbursed';

//MGA statuses
export const UNVERIFIED = 'UNVERIFIED';
export const VERIFIED = 'VERIFIED';
export const AUTHORITY_REQUEST = 'AUTHORITY_REQUEST';
export const AUTHORITY_REQUEST_APPROVED = 'AUTHORITY_REQUEST_APPROVED';
export const AUTHORITY_REQUEST_DENIED = 'AUTHORITY_REQUEST_DENIED';
export const MGA_IN_REVIEW = 'IN_REVIEW';
export const MEDICAL_RECORDS_UPDATED = 'MEDICAL_RECORDS_UPDATED';
export const HOLD_LAPSED = 'HOLD_LAPSED';
export const INFORMATION_UPDATED = 'INFORMATION_UPDATED';
export const HOLD_FOR_MEDICAL_RECORDS = 'HOLD_FOR_MEDICAL_RECORDS';
export const HOLD_FOR_MEDICAL_RECORDS_2 = 'HOLD_FOR_MEDICAL_RECORDS_2';
export const HOLD_FOR_MEDICAL_RECORDS_3 = 'HOLD_FOR_MEDICAL_RECORDS_3';
export const HOLD_FOR_INFORMATION = 'HOLD_FOR_INFORMATION';
export const WITHDRAWN = 'WITHDRAWN';
export const MGA_DUPLICATE = 'DUPLICATE';
export const DENIED = 'DENIED';
export const REIMBURSEMENT_REVIEW = 'REIMBURSEMENT_REVIEW';
export const APPROVED_PENDING_REIMBURSEMENT = 'APPROVED_PENDING_REIMBURSEMENT';
export const MGA_APPROVED = 'APPROVED';
export const ESTIMATE_COMPLETE = 'ESTIMATE_COMPLETE';
export const APPROVED_PENDING_VET_REIMBURSEMENT =
  'APPROVED_PENDING_VET_REIMBURSEMENT';
export const PENDING_DENIAL = 'PENDING_DENIAL';

export const claimMGAStatusesFilter = [
  { id: UNVERIFIED, name: 'UNVERIFIED' },
  { id: VERIFIED, name: 'VERIFIED' },
  { id: MGA_IN_REVIEW, name: 'IN REVIEW' },
  { id: MEDICAL_RECORDS_UPDATED, name: 'MEDICAL RECORDS UPDATED' },
  { id: INFORMATION_UPDATED, name: 'INFORMATION UPDATED' },
  { id: HOLD_LAPSED, name: 'HOLD - LAPSED' },
  { id: HOLD_FOR_MEDICAL_RECORDS, name: 'HOLD FOR MEDICAL RECORDS' },
  { id: HOLD_FOR_MEDICAL_RECORDS_2, name: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 2' },
  { id: HOLD_FOR_MEDICAL_RECORDS_3, name: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 3' },
  { id: HOLD_FOR_INFORMATION, name: 'HOLD FOR INFORMATION' },
  { id: WITHDRAWN, name: 'WITHDRAWN' },
  { id: MGA_DUPLICATE, name: 'DUPLICATE' },
  { id: DENIED, name: 'DENIED' },
  { id: REIMBURSEMENT_REVIEW, name: 'REIMBURSEMENT REVIEW' },
  { id: MGA_APPROVED, name: 'APPROVED' },
  { id: ESTIMATE_COMPLETE, name: 'ESTIMATE - COMPLETE' },
  { id: APPROVED_PENDING_REIMBURSEMENT, name: 'APPROVED - PENDING REIMBURSEMENT' },
  {
    id: APPROVED_PENDING_VET_REIMBURSEMENT,
    name: 'APPROVED - PENDING VET REIMBURSEMENT',
  },
  { id: AUTHORITY_REQUEST, name: 'AUTHORITY REQUEST' },
  { id: AUTHORITY_REQUEST_APPROVED, name: 'AUTHORITY REQUEST - APPROVED' },
  { id: AUTHORITY_REQUEST_DENIED, name: 'AUTHORITY REQUEST - DENIED' },
  { id: PENDING_DENIAL, name: 'PENDING DENIAL' },
];

export const claimMGAStatuses = {
  [UNVERIFIED]: 'UNVERIFIED',
  [VERIFIED]: 'VERIFIED',
  [AUTHORITY_REQUEST]: 'AUTHORITY REQUEST',
  [AUTHORITY_REQUEST_APPROVED]: 'AUTHORITY REQUEST - APPROVED',
  [AUTHORITY_REQUEST_DENIED]: 'AUTHORITY REQUEST - DENIED',
  [MGA_IN_REVIEW]: 'IN REVIEW',
  [MEDICAL_RECORDS_UPDATED]: 'MEDICAL RECORDS UPDATED',
  [INFORMATION_UPDATED]: 'INFORMATION UPDATED',
  [HOLD_LAPSED]: 'HOLD - LAPSED',
  [HOLD_FOR_MEDICAL_RECORDS]: 'HOLD FOR MEDICAL RECORDS',
  ['HOLD_FOR_MEDICAL_RECORDS_2']: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 2',
  ['HOLD_FOR_MEDICAL_RECORDS_3']: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 3',
  [HOLD_FOR_INFORMATION]: 'HOLD FOR INFORMATION',
  [WITHDRAWN]: 'WITHDRAWN',
  [MGA_DUPLICATE]: 'DUPLICATE',
  [DENIED]: 'DENIED',
  [REIMBURSEMENT_REVIEW]: 'REIMBURSEMENT REVIEW',
  [APPROVED_PENDING_REIMBURSEMENT]: 'APPROVED - PENDING REIMBURSEMENT',
  [MGA_APPROVED]: 'APPROVED',
  [ESTIMATE_COMPLETE]: 'ESTIMATE - COMPLETE',
  [APPROVED_PENDING_VET_REIMBURSEMENT]: 'APPROVED - PENDING VET REIMBURSEMENT',
  [PENDING_DENIAL]: 'PENDING DENIAL',
};

export const claimStatuses = [
  { id: 'Submitted', name: SUBMITTED },
  { id: 'In Review', name: IN_REVIEW },
  { id: 'Completed', name: COMPLETED },
  { id: 'Duplicate', name: DUPLICATE },
  { id: 'pending_assignment', name: PENDING_ASSIGNMENT },
];

export const claimFilterStatuses = [
  { id: '', name: ALL },
  { id: 'Submitted', name: SUBMITTED },
  { id: 'in_review', name: IN_REVIEW },
  { id: 'Completed', name: COMPLETED },
  { id: 'Duplicate', name: DUPLICATE },
  { id: 'pending_assignment', name: PENDING_ASSIGNMENT },
];

export const toDoDashboardStatuses = [
  { id: UNVERIFIED, name: 'UNVERIFIED' },
  { id: PENDING_DENIAL, name: 'PENDING DENIAL' },
];

export const inProgressDashboardStatuses = [
  { id: VERIFIED, name: 'VERIFIED' },
  { id: MGA_IN_REVIEW, name: 'IN REVIEW' },
  { id: HOLD_LAPSED, name: 'HOLD - LAPSED' },
  { id: HOLD_FOR_MEDICAL_RECORDS, name: 'HOLD FOR MEDICAL RECORDS' },
  { id: HOLD_FOR_MEDICAL_RECORDS_2, name: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 2' },
  { id: HOLD_FOR_MEDICAL_RECORDS_3, name: 'HOLD FOR MEDICAL RECORDS - ATTEMPT 3' },
  { id: HOLD_FOR_INFORMATION, name: 'HOLD FOR INFORMATION' },
  { id: MEDICAL_RECORDS_UPDATED, name: 'MEDICAL RECORDS UPDATED' },
  { id: INFORMATION_UPDATED, name: 'INFORMATION UPDATED' },
  { id: AUTHORITY_REQUEST, name: 'AUTHORITY REQUEST' },
  { id: AUTHORITY_REQUEST_APPROVED, name: 'AUTHORITY REQUEST - APPROVED' },
  { id: AUTHORITY_REQUEST_DENIED, name: 'AUTHORITY REQUEST - DENIED' },
  { id: REIMBURSEMENT_REVIEW, name: 'REIMBURSEMENT REVIEW' },
];

export const completedDashboardStatuses = [
  { id: APPROVED_PENDING_REIMBURSEMENT, name: 'APPROVED - PENDING REIMBURSEMENT' },
  {
    id: APPROVED_PENDING_VET_REIMBURSEMENT,
    name: 'APPROVED - PENDING VET REIMBURSEMENT',
  },
  { id: ESTIMATE_COMPLETE, name: 'ESTIMATE - COMPLETE' },
  { id: DENIED, name: 'DENIED' },
  { id: MGA_DUPLICATE, name: 'DUPLICATE' },
  { id: WITHDRAWN, name: 'WITHDRAWN' },
  { id: MGA_APPROVED, name: 'APPROVED' },
];

export const preventClaimDecisions = [
  {
    id: APPROVED,
    name: 'Approve',
  },
  {
    id: DECLINED,
    name: 'Decline',
  },
  {
    id: INSURANCE_PENDING,
    name: 'Insurance Pending',
  },
  {
    id: DECIDE_LATER,
    name: 'Decide Later',
  },
];

export const denialReasons = [
  {
    id: `Not covered under Preventive Essentials`,
    name: `Not covered under Preventive Essentials`,
  },
  {
    id: `Not covered under customer's plan`,
    name: `Not covered under customer's plan`,
  },
  { id: `Covered under insurance policy`, name: `Covered under insurance policy` },
  {
    id: `Exceeds item's yearly allowed quantity`,
    name: `Exceeds item's yearly allowed quantity`,
  },
  {
    id: `Processed under a different claim request`,
    name: `Processed under a different claim request`,
  },
  { id: `Plan has expired or cancelled`, name: `Plan has expired or cancelled` },
  { id: `Met Bundle Cap`, name: `Met Bundle Cap` },
  { id: `Met Annual Cap`, name: `Met Annual Cap` },
  { id: `Other`, name: 'Other' },
];
