import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
} from 'react-admin';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import RoutePaths from '../../../../routes';
import { formatCurrency } from '../../../../shared/utils';
import { AuditLogsLink } from '../../../audit-logs';
import FieldStatusChip from '../field-status-chip';
import petShowStyle from '../pet-show-style';

const SPECIES_MAP = {
  dog: 'Puppy/Dog',
  cat: 'Kitten/Cat',
};

const TIER_MAP = {
  essential: 'Essential Plan',
  premium: 'Premium Plan',
};

function CustomDatagridHeader({ children }) {
  const classes = petShowStyle();

  return (
    <TableHead>
      <TableRow>
        {children.map((child, idx, arr) => {
          return (
            <TableCell
              key={(child.props.label || child.props.source) + '_' + idx}
              className={classes.colHeader}
              sx={arr.length - 1 === idx ? { borderTopRightRadius: '4px' } : {}}
            >
              {child.props.label || child.props.source}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

CustomDatagridHeader.propTypes = {
  children: PropTypes.node,
};

function WellnessDatagrid(props) {
  const classes = petShowStyle();

  return (
    <>
      <Typography className={classes.title} sx={{ margin: '20px 0' }}>
        Wellness
      </Typography>

      <SimpleShowLayout className={classes.root} {...props}>
        <ReferenceManyField
          label={''}
          reference={RoutePaths.wellness}
          target='pet_id'
          sort={{ field: 'created_at', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            header={<CustomDatagridHeader />}
            data-id='wellness-datagrid'
          >
            <FieldStatusChip
              label='status'
              source='status'
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Cost'
              render={(record = {}) => formatCurrency(record.price)}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Description'
              render={(record = {}) =>
                `${SPECIES_MAP[record.tier.split('.')[1]]} ${
                  TIER_MAP[record.tier.split('.')[0]]
                }`
              }
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Effective Date'
              render={(record = {}) => {
                return <DateFieldIgnoringZone source='start_date' record={record} />;
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='End Date'
              render={(record = {}) => {
                return <DateFieldIgnoringZone source='end_date' record={record} />;
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Is annual?'
              render={(record = {}) => (record.is_annualized ? 'true' : 'false')}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label=''
              render={(record = {}) => {
                return <AuditLogsLink filter={{ wellness_id: record.id }} />;
              }}
              headerClassName={classes.colHeader}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
}

export default WellnessDatagrid;
