import { useEffect, useState } from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  TextField,
  useRecordContext,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import { getTimeDifferenceFromCurrentDate } from '../../../shared/utils';
import { claimIncidentHistoryStyle } from './claim-incident-history-style';

export default function ClaimIncidentHistory(props) {
  const {
    onAddIncident,
    onEditIncident,
    onDeleteIncident,
    incidentHistoryData,
    disableButton,
  } = props;

  const record = useRecordContext();
  const [expandRow, setExpandRow] = useState([]);
  useEffect(() => {
    for (let i = 0; i < incidentHistoryData.length; i++) {
      setExpandRow(prevState => [
        ...prevState,
        { incident_number: incidentHistoryData[i].incident_number, open: true },
      ]);
    }
  }, [incidentHistoryData]);

  function onExpandAllRows() {
    const row = expandRow.map(row => {
      return { ...row, open: true };
    });
    setExpandRow(row);
  }

  function onCollapseAllRows() {
    const row = expandRow.map(row => {
      return { ...row, open: false };
    });
    setExpandRow(row);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '79%',
          }}
        >
          <Typography
            sx={claimIncidentHistoryStyle.title}
            variant='h6'
            color='primary'
          >
            Incident History
          </Typography>

          <Button
            variant='contained'
            color='inherit'
            size='small'
            endIcon={<AddIcon />}
            disabled={disableButton}
            onClick={() => onAddIncident(record?.pet_id)}
            sx={{
              backgroundColor: '#FEC600',
            }}
          >
            NEW INCIDENT
          </Button>
        </div>

        <div>
          <ButtonGroup color='primary' orientation='horizontal' variant='text'>
            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={() => onExpandAllRows(record.pet_id)}
            >
              EXPAND ALL
            </Button>

            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={() => onCollapseAllRows(record.pet_id)}
            >
              COLLAPSE ALL
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <ArrayField
        source='incidents'
        record={{
          incidents: incidentHistoryData,
        }}
      >
        <Paper>
          <Datagrid
            sx={claimIncidentHistoryStyle.dataGrid}
            bulkActionButtons={false}
            rowClick={(id, basePath, record) => onEditIncident(id, record)}
          >
            <FunctionField
              label='Diagnosis'
              source='diagnosis'
              sortable={false}
              render={(record = {}) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <FunctionField
                        render={record => (
                          <Typography variant='subtitle2'>
                            {record?.sub_diagnosis?.name}
                          </Typography>
                        )}
                      />

                      <div>
                        <Typography variant='caption' color='primary'>
                          {record.type == 'accident'
                            ? 'Accident'
                            : record.type == 'illness'
                              ? 'Illness'
                              : 'Wellness'}{' '}
                          &nbsp;
                        </Typography>
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          {record?.diagnosis?.name}
                        </span>
                      </div>
                    </div>
                    {record.pre_existing_condition && (
                      <Chip
                        label='PRE-X'
                        size='small'
                        sx={{
                          color: 'white',
                          backgroundColor: '#C62828',
                          fontWeight: '700',
                          width: '48px',
                          height: '22px',
                          fontSize: '10px',
                          marginTop: '4px',
                        }}
                      />
                    )}
                  </div>
                );
              }}
            />

            <TextField
              sx={claimIncidentHistoryStyle.onSetDate}
              label='Onset'
              source='original_symptom_date'
              sortBy='original_symptom_date_as_date'
            />

            <FunctionField
              label='Latest Occ.'
              source='latest_occurrence_date'
              sortable={false}
              render={(record = {}) => {
                return (
                  <>
                    <TextField width='65px' source='latest_occurrence_date' />
                    <div>
                      {record.is_curable === 'curable' && record.end_date ? (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Cured
                        </span>
                      ) : record.is_curable === 'curable' ? (
                        <>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#6B717E',
                            }}
                          >
                            Curable &nbsp;
                          </span>

                          <span
                            style={{
                              fontSize: '12px',
                              color: '#EF6C00',
                              fontWeight: '500',
                            }}
                          >
                            (
                            {getTimeDifferenceFromCurrentDate(
                              record.latest_occurrence_date,
                              'days'
                            )}
                            d)
                          </span>
                        </>
                      ) : record.is_curable === 'incurable' ? (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Incurable
                        </span>
                      ) : (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Unknown
                        </span>
                      )}
                    </div>
                  </>
                );
              }}
            />

            <FunctionField
              label='Medical Record Reference / Notes'
              source='notes'
              sortable={false}
              sx={{ position: 'relative', minWidth: '50px' }}
              render={(record = {}) => {
                const isOpen = expandRow.filter(obj => {
                  return obj.incident_number === record.incident_number;
                })[0]?.open;
                const lineBreaks = record?.notes?.split('\n');

                return (
                  <Collapse
                    in={isOpen}
                    collapsedSize={44}
                    sx={{
                      display: 'flex',
                      '& .MuiCollapse-wrapperInner': {
                        display: 'flex',
                      },
                    }}
                  >
                    {!isOpen &&
                    (lineBreaks?.length > 1 || record?.notes?.length > 90) ? (
                      <MoreHorizIcon
                        sx={{ opacity: '56%', position: 'absolute', top: '68%' }}
                      />
                    ) : null}

                    <TextField
                      sx={{
                        width: '300px',
                        wordWrap: 'break-word',
                        whiteSpace: 'break-spaces',
                        paddingLeft: '4px',
                      }}
                      source='notes'
                    />
                  </Collapse>
                );
              }}
            />

            <FunctionField
              sortable={false}
              render={(record = {}) => {
                return (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();

                      const row = expandRow.map(row =>
                        row.incident_number == record.incident_number
                          ? { ...row, open: !row.open }
                          : row
                      );
                      setExpandRow(row);
                    }}
                    sx={claimIncidentHistoryStyle.expandIcon}
                    disabled={disableButton}
                    size='small'
                  >
                    {expandRow.filter(obj => {
                      return obj.incident_number === record.incident_number;
                    })[0]?.open ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                );
              }}
            />
            <FunctionField
              sortable={false}
              render={(record = {}) => {
                return (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteIncident(record);
                    }}
                    sx={claimIncidentHistoryStyle.deleteIcon}
                    data-testid='delete-incident-button'
                    disabled={!record.is_deletable}
                    size='small'
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              }}
            />
          </Datagrid>
        </Paper>
      </ArrayField>
    </>
  );
}
